import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Auth from '../views/AuthorizationsViews/Auth.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/relatorio-notas-fiscais',
        name: 'RelatorioNotasFiscais',
        component: () => import(/* webpackChunkName: "RelatorioNotasFiscais" */ '../views/RelatorioNotasFiscais.vue')
      },
      {
        path: '/relatorio-posicao-estoque',
        name: 'RelatorioPosicaoEstoque',
        component: () => import(/* webpackChunkName: "RelatorioPosicaoEstoque" */ '../views/RelatorioPosicaoEstoque.vue')
      },
      {
        path: '/relatorio-movimentacoes-fisicas',
        name: 'RelatorioMovimentacoesFisicas',
        component: () => import(/* webpackChunkName: "RelatorioMovimentacoesFisicas" */ '../views/RelatorioMovimentacoesFisicas.vue')
      },
      {
        path: '/relatorio-balanco-de-massa',
        name: 'RelatorioBalancoDeMassa',
        component: () => import(/* webpackChunkName: "RelatorioBalancoDeMassa" */ '../views/RelatorioBalancoDeMassa.vue')
      },
      {
        path: '/relatorio-relatorio-variacao-contagem-rgb',
        name: 'RelatorioVariacaoContagemRgb',
        component: () => import(/* webpackChunkName: "RelatorioVariacaoContagemRgb" */ '../views/RelatorioVariacaoContagemRgb.vue')
      },
      {
        path: '/relatorio-historico-variacao-produtos',
        name: 'RelatorioHistoricoDeVariacaoProdutos',
        component: () => import(/* webpackChunkName: "RelatorioHistoricoDeVariacaoProdutos" */ '../views/RelatorioHistoricoDeVariacaoProdutos.vue')
      },
      {
        path: '/relatorio-porcentagem-segunda-contagem',
        name: 'PorcentagemSegundaContagem',
        component: () => import(/* webpackChunkName: "PorcentagemSegundaContagem" */ '../views/PorcentagemSegundaContagem.vue')
      },
      {
        path: '/relatorio-contagens',
        name: 'RelatorioContagens',
        component: () => import(/* webpackChunkName: "RelatorioVariacaoContagemRgb" */ '../views/RelatorioContagens.vue')
      },
      {
        path: '/relatorio-produtos-estoque',
        name: 'RelatorioProdutosEstoque',
        component: () => import(/* webpackChunkName: "RelatorioVariacaoContagemRgb" */ '../views/RelatorioProdutosEstoque.vue')
      },
      {
        path: '/analises/erros-faturamento-de-pedidos',
        name: 'RelatorioErrosFaturamentoPedidos',
        component: () => import(/* webpackChunkName: "RelatorioErrosFaturamentoPedidos" */ '../views/RelatorioErrosFaturamentoPedidos.vue')
      },
      {
        path: '/analises/relatorio-estoque-fisico-vs-gerencial',
        name: 'EstoqueFisicoVsGerencial',
        component: () => import(/* webpackChunkName: "EstoqueFisicoVsGerencial" */ '../views/EstoqueFisicoVsGerencial.vue')
      },
      {
        path: '/analises/relatorio-diferenca-pedidos-faturados',
        name: 'DiferencaPedidosFaturados',
        component: () => import(/* webpackChunkName: "DiferencaPedidosFaturados" */ '../views/DiferencaPedidosFaturados.vue')
      },
      {
        path: '/integracoes/farol-integracoes',
        name: 'IntegrationsControl',
        component: () => import(/* webpackChunkName: "IntegrationsControl" */ '../views/IntegrationsViews/IntegrationsControl.vue')
      },
      {
        path: '/estoque/inventario-consolidado',
        name: 'InventarioConsolidado',
        component: () => import(/* webpackChunkName: "InventarioConsolidado" */ '../views/Estoque/InventarioConsolidado.vue')
      },
    ]
  },
  {
    path: '/unauthorized',
    name: 'ErroAutenticacao',
    component: () => import(/* webpackChunkName: "ErroAutenticacao" */ '../views/AuthorizationsViews/ErroAutenticacao.vue')
  },
  {
    path: '/ir_para_reports/:auth_token',
    name: 'Auth',
    component: Auth
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  activeClass: 'active',
  exactActiveClass:'active-exact'
})

const withoutAuthorization = [
  "ErroAutenticacao",
  "Auth"
]

router.beforeEach((to, from, next) => {
  let token = window.localStorage.getItem("auth_token")

  if( withoutAuthorization.includes(to.name) || token ) {
    next()
  } else {
    next({ name: "ErroAutenticacao" })
  }
})

export default router
