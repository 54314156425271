export const Menu =[
  {
    label:"Fiscal",
    to:{},
    icon:'fa-solid fa-file-invoice-dollar',
    submenu:[
      {
        label:"NF-es e NFC-es emitidas",
        to:{ name: 'RelatorioNotasFiscais' },
      }
    ],
    checkActive:activeMenu
  },
  {
    label:"Estoque",
    to:{},
    icon:'fa-solid fa-boxes-stacked',
    submenu:[
      {
        label:"Relatório de faturamento",
        to:{ name: 'RelatorioPosicaoEstoque' },
        active:false
      },
      {
        label:"Relatório de movimentações físicas",
        to:{ name: 'RelatorioMovimentacoesFisicas' },
        active:false
      },
      {
        label: 'Relatório de balanço de massa',
        to: { name: 'RelatorioBalancoDeMassa' },
        active:false
      },
      {
        label: 'Relatório de variação - RGB',
        to: { name: 'RelatorioVariacaoContagemRgb' },
        active:false
      },
      {
        label: 'Relatório de histórico de variação dos produtos',
        to: { name: 'RelatorioHistoricoDeVariacaoProdutos' },
        active:false
      },
      {
        label: 'Relatório de contagens',
        to: { name: 'RelatorioContagens' },
        active:false
      },
      {
        label: 'Relatório de produtos em estoque',
        to: { name: 'RelatorioProdutosEstoque' },
        active:false
      },
      {
        label: 'Inventário consolidado',
        to: { name: 'InventarioConsolidado' },
        active: false
      },
    ],
    checkActive:activeMenu
  },
  {
    label: "Análise",
    to: {},
    icon: 'fa-solid fa-chart-column',
    submenu: [
      {
        label: 'Relatório de Porcentagem segunda contagem',
        to: { name: 'PorcentagemSegundaContagem' },
        active: false
      },
      {
        label: 'Relatório erros faturamento dos pedidos',
        to: { name: 'RelatorioErrosFaturamentoPedidos' },
        active: false
      },
      {
        label: 'Estoque físico x Estoque gerencial',
        to: { name: 'EstoqueFisicoVsGerencial' },
        active: false
      },
      {
        label: 'Total pedidos x Pedidos não faturados',
        to: { name: 'DiferencaPedidosFaturados' },
        active: false
      },
    ],
    checkActive:activeMenu
  },
  {
    label: "Integrações",
    to: {},
    icon: "fas fa-project-diagram",
    submenu: [
      {
        label: 'Tela de farol',
        to: { name: 'IntegrationsControl' },
        active: false
      },
    ],
    checkActive:activeMenu
  }
]

export function activeMenu(route){
  return this.submenu && this.submenu.some(sb=>sb.to.name === route.name)
}
